import React from 'react';

import { Layout, SEO } from './../components';
import { Link, graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { download_file } from '../utils/utils';

import { PDFViewer, PDFSlideViewer } from '../components/PDFViewer';
import PlaySoundButton from 'components/PlaySoundButton';
import VideoPlayer from 'components/VideoPlayer';

/**
 * @param {{
 *    data: {
 *      interactiveLearningPage: {
 *        description: string
 *        title: string
 *        horizontalPdf: boolean
 *        topYoutubeVideo?: string
 *        topVideo?: {
 *          video: {
 *              streamingUrl: string
 *           }
 *        },
 *        pdfLink?: string
 *        subscriptionOnly: boolean
 *        downloadablePdf: boolean
 *        video?: {
 *          video: {
 *              streamingUrl: string
 *           }
 *        },
 *        videos?: {
 *          video: {
 *              streamingUrl: string
 *          }
 *        }[]
 *        youtubeVideo?: string
 *        paragraphs?: {
 *          paragraph: string
 *          audioClip?: {
 *            url?: string
 *          }
 *          buttonLabel?: string
 *        }[]
 *        seo?: {
 *          title: string
 *          description: string
 *          image?: {
 *              url: string
 *          }
 *        }
 *        seoKeywords?: {
 *          keyword: string
 *        }[],
 *        schemaMarkup: string
 *      }
 *    }
 * }} param
 */
export default function InteractiveLearning({ data }) {
  const {
    description,
    title,
    topVideo,
    topYoutubeVideo,
    pdfLink,
    horizontalPdf,
    paragraphs,
    seo,
    seoKeywords,
    schemaMarkup,
    subscriptionOnly,
    video,
    videos,
    youtubeVideo,
    downloadablePdf,
  } = data.interactiveLearningPage;

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  React.useEffect(() => {
    if (subscriptionOnly && !isSubscribed) navigate('/interactive-learning');
  }, [user]);

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  return (
    <Layout>
      <SEO
        description={seo && seo.description}
        image={seo && seo.image}
        title={(seo && seo.title) ?? `Interactive Learning-${title}`}
        seoKeywords={seoKeywords}
        schema={schemaMarkup && JSON.parse(schemaMarkup)}
      />
      <div className="container content-section mb-2 pt-0 brainteaser-container">
        <Link className="brainteaser-breadcrumb" to="/interactive-learning">
          Interactive Learning
        </Link>
        <h1>{title}</h1>
        {(topVideo || topYoutubeVideo) && (
          <VideoPlayer src={topYoutubeVideo ?? topVideo.video.streamingUrl} />
        )}
        {paragraphs.length > 0 ? (
          paragraphs.map(({ paragraph, audioClip, buttonLabel }) => (
            <>
              <div dangerouslySetInnerHTML={{ __html: paragraph }} />
              {audioClip?.url && (
                <PlaySoundButton className="my-1" label={buttonLabel} soundUrl={audioClip.url} />
              )}
            </>
          ))
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {allVideos.length ? allVideos.map((src) => <VideoPlayer src={src} />) : null}
        {pdfLink && (
          <>
            {downloadablePdf && (
              <button className="button my-1" onClick={() => download_file(pdfLink, title)}>
                Download PDF
              </button>
            )}
            {horizontalPdf ? (
              <PDFSlideViewer url={pdfLink} hideWaterMark={true} />
            ) : (
              <PDFViewer url={pdfLink} hideWaterMark={true} toolbar={false} />
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

const query = graphql`
  query InteractiveLearningPageQuery($id: String!) {
    interactiveLearningPage: datoCmsInteractiveLearningPage(originalId: { eq: $id }) {
      title
      pdfLink
      horizontalPdf
      topVideo {
        video {
          streamingUrl
        }
      }
      topYoutubeVideo
      description
      subscriptionOnly
      youtubeVideo
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      downloadablePdf
      paragraphs {
        audioClip {
          url
        }
        paragraph
        buttonLabel
      }
      seo {
        description
        image {
          url
        }
        title
      }
      seoKeywords {
        keyword
      }
      schemaMarkup
    }
  }
`;

export { query };
